import { Link } from "gatsby"
import React from "react"
import { Seo } from "../components"
import Layout from "../components/static-layout"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Giải Pháp Vận Hành Phòng Net Toàn Diện" />
    <h1>404</h1>
    <p>Trang này không tồn tại.</p>
    <p>
      <Link to="/">Trở về trang chủ</Link>
    </p>
  </Layout>
)

export default NotFoundPage
